<script setup lang="ts">
import { isRef, onMounted, reactive, ref, shallowRef, toRef, toRefs, unref, useModel } from "vue";
import { useTheme } from "./hooks/useTheme";
import { MqttStore } from "@/stores/modules/mqtt";
import { useDictStore } from "@/stores/modules/dictionary";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";
import Test from '@/components/TyAiLabel/test.vue'
import autofit from 'autofit.js'
const locale = ref(zhCn);
const useMqtt = MqttStore();
const dictStore = useDictStore();
dictStore.initDict();
const { initTheme } = useTheme();
// 更新屏幕宽度的函数
onMounted(() => {
  initTheme();
  useMqtt.mqttMessage()
  const screenWidths = screen.width;
  const screenHeights = screen.height;
  
    autofit.init({
        dh: screenHeights,
        dw: screenWidths,
        el:"body",
        resize: true,
        limit:0.3
    })
});
</script>
<template>
  <el-config-provider :locale="locale">
    <router-view></router-view>
  </el-config-provider>
</template>

<style>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
* {
  font-size: 16px; /* 设置所有元素的默认字体大小为16px */
}
.is-largeScreen span,
.is-largeScreen p,
.is-largeScreen h1,
.is-largeScreen h2,
.is-largeScreen h3,
.is-largeScreen h4,
.is-largeScreen h5,
.is-largeScreen h6,
.is-largeScreen .el-card__header {
  transform: scaleY(1.2) !important;
}
.is-largeScreen tr.vxe-body--row{
  transform: scaleY(1.2) !important;
}
.is-largeScreen tr.vxe-header--row{
  transform: scaleY(1.2) !important;
}
</style>
